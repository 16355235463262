import { Link } from '@remix-run/react';

const FOOTER_LINKS = [
  {
    title: 'Get a quote',
    href: '/',
  },
  {
    title: 'About us',
    href: '/about',
  },
  {
    title: 'Contact us',
    href: '/contact',
  },
  {
    title: 'Help',
    href: '/help',
  },
  {
    title: 'Suppliers',
    href: 'https://hipages.com.au/registration',
  },
  {
    title: 'Privacy',
    href: '/privacy',
  },
  {
    title: 'Terms of use',
    href: '/termsofuse',
  },
];

const Footer = () => {
  const afterClassName =
    'flex items-center gap-ml relative after:content-[""] after:bg-content-muted after:w-[1px] after:h-[12px]';

  return (
    <footer className="hidden md:block py-lg bg-surface text-content-muted">
      <nav className="max-w-[1008px] mx-auto px-md">
        <ul className="flex flex-wrap items-center justify-center gap-ml text-body">
          {FOOTER_LINKS.map(({ title, href }, index) => (
            <li
              className={index === FOOTER_LINKS.length - 1 ? undefined : afterClassName}
              key={`footer-item-${href}`}
            >
              <Link to={href} className="hover:text-content-link">
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
