import { Outlet, useLoaderData } from '@remix-run/react';
import { json, type LoaderFunctionArgs } from '@remix-run/node';
import Footer from '~/components/footer/Footer';
import Header from '~/components/header/Header';
import { WizardProxy, type CategoryOptionShape } from '@hipagesgroup/get-quotes';
import { ClientOnly } from 'remix-utils/client-only';
import { cerebroUrl } from '~/clients';
import { buildUrl } from '@hipagesgroup/utils';
import { GetQuotesPackageWrapperProvider } from '~/components/providers/GetQuotesPackageWrapper';

async function getPrefilledCategory(
  categoryName: string | unknown
): Promise<CategoryOptionShape | null> {
  try {
    if (typeof categoryName !== 'string') {
      return null;
    }

    // Endpoint code:
    // https://github.com/hipagesgroup/cerebro/blob/ada7742bca2b1add2b62c9f0b0d6358d192f2482/src/controller/ElasticController.ts#L122
    const endpoint = buildUrl(cerebroUrl, `suggest/category-fuzzy?keyword=${categoryName}&size=1`);
    const result = await fetch(endpoint);

    if (!result.ok) {
      throw new Error(`Category fetch failed for ${categoryName}`);
    }

    const data = await result.json();
    const categoryFromSearch = data[0].list[0];

    return {
      categoryId: categoryFromSearch.id,
      categoryKey: categoryFromSearch.seoKey,
      label: categoryFromSearch.name,
    };
  } catch (e) {
    console.log('Error:', e);
    return null;
  }
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const prefilledCategoryName = new URL(request.url).searchParams.get('e');
  return json({
    prefilledCategory: await getPrefilledCategory(prefilledCategoryName),
  });
};

export interface TemplateProps {
  prefilledCategory: CategoryOptionShape | null;
}

export default function Template() {
  const { prefilledCategory } = useLoaderData<typeof loader>();

  return (
    <GetQuotesPackageWrapperProvider prefilledCategory={prefilledCategory}>
      <div className="bg-surface">
        <Header />
        <Outlet />
        <Footer />
        <ClientOnly>{() => <WizardProxy />}</ClientOnly>
      </div>
    </GetQuotesPackageWrapperProvider>
  );
}
